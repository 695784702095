<template>
  <div>
    <el-page-header @back="back" content="关联退货单"></el-page-header>
    <div class="AssociatedOrderItem">
      <router-link :to="{
        name: 'ReturnOrderDetails', query: {
          key: $UrlEncode.encode(JSON.stringify({
            id: item.orderReturnId
          }))
        }
      }" class="f-w item mb-20" v-for="(item, index) of AssociatedOrderList" :key="index">
        <div class="textVal">
          负责人： <span class="textValSpan">{{ item.lastModifyUsername }}</span>
        </div>
        <div class="textVal">
          客户名称： <span class="textValSpan">{{ item.customerName }}</span>
        </div>
        <div class="textVal">
          创建日期： <span class="textValSpan">{{ item.createDate }}</span>
        </div>
        <div class="textVal">
          退货单编号： <span class="textValSpan">{{ item.returnOrderNumber }}</span>
        </div>
        <div class="textVal">
          订单金额：
          <span style="color:red;">{{ item.valuationCurrency +
            item.returnRealityAmount2
          }}</span>
        </div>
        <div class="textVal f-c">
          订单状态：
          <div style="width: 90px;height: 30px;text-align: center;line-height: 30px;border-radius: 10px;"
            :style="{ 'background': item.returnStatusbgColor }">
            <span :style="{ color: item.returnStatusColor }">{{
              item.returnStatusText
            }}</span>
          </div>
        </div>
        <!-- <div>退货单编号：<span>{{ item.returnOrderNumber }}</span></div>
        <div>创建日期：<span>{{ item.createDate }}</span></div>
        <div>负责人：<span>{{ item.lastModifyUsername }}</span>
        </div> -->
      </router-link>
    </div>
    <Pagination :total="total" :pageNum.sync="currentPage" :limit.sync="size" :layout="layout" @pagination="fetchData" />
  </div>
</template>

<script>
import { UrlDecodeId } from '@/mixins/UrlDecodeMixin';
import { PaginationRetention } from '@/mixins/PaginationRetentionMixin';
export default {
  name: 'AssociatedOrder',
  mixins: [UrlDecodeId, PaginationRetention],
  data() {
    return {
      AssociatedOrderList: []
    }
  },
  watch: {
    '$route.path': {
      handler(newVal) {
        let pageLayer = this.$getStorage('pageLayer') ? this.$getStorage('pageLayer') : 0;
        if (pageLayer && pageLayer.length == 2) {
          pageLayer.push(newVal)
          this.$setStorage('pageLayer', pageLayer)
        }
      },
      // 深度观察监听
      deep: true,
      immediate: true
    }
  },
  methods: {
    back() {
      let pageLayer = this.$getStorage('pageLayer');
      pageLayer && pageLayer.length > 0 ? this.$setStorage('pageLayer', pageLayer.slice(0, -1)) : pageLayer
      this.$common.DelayedBack(0);
      this.$removeStorage('fetchDataPage')
      this.$removeStorage('fetchDataSize')
    },
    getDate() {
      this.findAllOrderReturnList(this.paging, this.pagingSize)
    },
    // 用户查看关联单的全部退货列表接口
    findAllOrderReturnList(page = this.page, size = this.size) {
      let that = this;
      that.$http.findAllOrderReturnList({
        lastModifyUserId: parseInt(that.$store.state.userId),
        token: that.$getStorage('token'),
        orderId: that.queryId,
        page,
        size
      }).then((res) => {
        res.data.records.forEach(item => {
          if (item.returnStatus == 'returned') {
            item.returnStatusText = '已完成';
            item.returnStatusColor = '#C49423';
            item.returnStatusbgColor = '#F9F4E9';
          } else if (item.returnStatus == 'returning') {
            item.returnStatusText = '退货中';
            item.returnStatusColor = '#0FBA13';
            item.returnStatusbgColor = '#E2F7E2';
          } else if (item.returnStatus == 'Canceled') {
            item.returnStatusText = '已取消';
            item.returnStatusColor = '#EF2C2C';
            item.returnStatusbgColor = '#FDE9E9';

          }
        })
        that.AssociatedOrderList = res.data.records
        that.currentPage = res.data.current;//当前页数
        that.total = res.data.total;
      })
    },
  },
}

</script>
<style lang='less' scoped>
@import '@/assets/css/JumpListItem.less';
</style>
