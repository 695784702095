var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-page-header',{attrs:{"content":"关联退货单"},on:{"back":_vm.back}}),_c('div',{staticClass:"AssociatedOrderItem"},_vm._l((_vm.AssociatedOrderList),function(item,index){return _c('router-link',{key:index,staticClass:"f-w item mb-20",attrs:{"to":{
      name: 'ReturnOrderDetails', query: {
        key: _vm.$UrlEncode.encode(JSON.stringify({
          id: item.orderReturnId
        }))
      }
    }}},[_c('div',{staticClass:"textVal"},[_vm._v(" 负责人： "),_c('span',{staticClass:"textValSpan"},[_vm._v(_vm._s(item.lastModifyUsername))])]),_c('div',{staticClass:"textVal"},[_vm._v(" 客户名称： "),_c('span',{staticClass:"textValSpan"},[_vm._v(_vm._s(item.customerName))])]),_c('div',{staticClass:"textVal"},[_vm._v(" 创建日期： "),_c('span',{staticClass:"textValSpan"},[_vm._v(_vm._s(item.createDate))])]),_c('div',{staticClass:"textVal"},[_vm._v(" 退货单编号： "),_c('span',{staticClass:"textValSpan"},[_vm._v(_vm._s(item.returnOrderNumber))])]),_c('div',{staticClass:"textVal"},[_vm._v(" 订单金额： "),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(item.valuationCurrency + item.returnRealityAmount2))])]),_c('div',{staticClass:"textVal f-c"},[_vm._v(" 订单状态： "),_c('div',{staticStyle:{"width":"90px","height":"30px","text-align":"center","line-height":"30px","border-radius":"10px"},style:({ 'background': item.returnStatusbgColor })},[_c('span',{style:({ color: item.returnStatusColor })},[_vm._v(_vm._s(item.returnStatusText))])])])])}),1),_c('Pagination',{attrs:{"total":_vm.total,"pageNum":_vm.currentPage,"limit":_vm.size,"layout":_vm.layout},on:{"update:pageNum":function($event){_vm.currentPage=$event},"update:page-num":function($event){_vm.currentPage=$event},"update:limit":function($event){_vm.size=$event},"pagination":_vm.fetchData}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }